// DescriptiveInput.tsx

import React, { useState } from 'react';

interface Props {
  label: string;
  placeholder: string;
  validator: (value: string) => boolean;
  onValueChange: (value: string) => void;
  initValue?: string;
  type?: string;
}

export const DescriptiveInput: React.FC<Props> = ({
  label,
  placeholder,
  validator,
  onValueChange,
  initValue,
  type
}) => {
  const [value, setValue] = useState<string>(initValue ? initValue.toString() : '');
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);

    const validationStatus = validator(inputValue);
    setIsValid(validationStatus);

    if (validationStatus) {
      onValueChange(inputValue);
    }
  };

  return (
    <div>
      <label>{label}</label>
      <input
        type={type ? type : 'text'}
        className="react-select-style-input"
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        style={isValid ? {} : { borderColor: 'red' }}
      />
      {!isValid && <p style={{ color: 'red' }}>Please enter in the correct format.</p>}
    </div>
  );
};
