import React from 'react';
import { DropdownProps } from './dropdown-props';
import Select from 'react-select';
import { OptionType } from './option-type';

export const leadGroupOptions: OptionType[] = [
  { label: 'All Leads', value: 0 },
  { label: 'Cluster 1', value: 1 },
  { label: 'Cluster 2', value: 2 },
  { label: 'Cluster 3', value: 3 },
  { label: 'Cluster 4', value: 4 },
  { label: 'Cluster 5', value: 5 }
];

export const LeadGroupDropdown: React.FC<DropdownProps> = ({ selectedOption, setSelectedOption }) => {
  return <Select value={selectedOption} onChange={setSelectedOption} options={leadGroupOptions} />;
};
