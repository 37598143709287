import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { loadScript } from './util/lead-script';
import { ClusterResultDto } from './models/cluster-result.dto';
import MapComponent from './map/map-component';
import { minuteDropdownOptions, MinutesDropdown } from './components/minutes-dropdown';
import { DescriptiveInput } from './components/descriptive-input';
import { LeadGroupDropdown, leadGroupOptions } from './components/lead-group-dropdown';
import { ExportTypeDropdown, exportTypeOptions } from './components/export-type-dropdown';
import { exportLeads } from './export-leads';
import moment from 'moment';

const intervalMs = 30000;

function Main() {
  const validateStates = (value: string) => {
    if (!value) return true; // Allow empty or undefined
    return /^[A-Z]{2}(,[A-Z]{2})*$/.test(value);
  };
  const validateNumbers = (value: string) => {
    if (!value) return true; // Allow empty or undefined
    return /^\d+(,\d+)*$/.test(value);
  };
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [status, setStatus] = useState('pending');
  const [clusterResult, setClusterResult] = useState<ClusterResultDto | undefined>(undefined);
  const [minutes, setMinutes] = useState(minuteDropdownOptions[0]);
  const [groupType, setGroupType] = useState(leadGroupOptions[0]);
  const [exportType, setExportType] = useState(exportTypeOptions[0]);
  const [states, setStates] = useState<string | undefined>(undefined);
  const [sourceIds, setSourceIds] = useState<string | undefined>(undefined);
  const [daysBack, setDaysBack] = useState<number>(30);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingClusters, setLoadingClusters] = useState(false);
  const [totals, setTotals] = useState({ lessThen30: 0, lessThen60: 0, lessThen90: 0, greaterThen90: 0 });

  useEffect(() => {
    const apiEndpoint = process.env.REACT_APP_SCHEDULER_API;
    if (apiEndpoint) {
      loadScript(`${apiEndpoint}/cluster/load-google-maps`, () => {
        console.log('Google Maps script loaded!');
        setScriptLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    const leads = clusterResult?.leads || [];
    const totals = {
      lessThen30: 0,
      lessThen60: 0,
      lessThen90: 0,
      greaterThen90: 0
    };
    if (!leads.length) {
      setTotals(totals);
    }
    for (const lead of leads) {
      if (moment(lead.createdAt).isAfter(moment().subtract(30, 'days'))) {
        totals.lessThen30 += 1;
      } else if (moment(lead.createdAt).isAfter(moment().subtract(60, 'days'))) {
        totals.lessThen60 += 1;
      } else if (moment(lead.createdAt).isAfter(moment().subtract(90, 'days'))) {
        totals.lessThen90 += 1;
      } else {
        totals.greaterThen90 += 1;
      }
    }
    setTotals(totals);
  }, [clusterResult]);
  const fetchData = async (isInterval: boolean) => {
    if (!isInterval) {
      setLoading(true);
    }
    try {
      let url = `${process.env.REACT_APP_SCHEDULER_API}/cluster`;
      const minutesValue = minutes?.value || 60;
      url += `?minutes=${minutesValue}`;
      if (sourceIds) {
        url += `&sourceIds=${sourceIds}`;
      }
      if (states) {
        url += `&states=${states}`;
      }
      url += `&daysBack=${daysBack > 0 ? daysBack : 30}`;
      const response = await fetch(url);
      const result: ClusterResultDto = await response.json();
      if (isInterval && status === result.status) {
        return result.status;
      }
      setStatus(result.status);
      setClusterResult(result);
      if (result.status === 'pending') {
        setLoadingClusters(true);
      } else {
        setLoadingClusters(false);
      }
      if (!isInterval) {
        setLoading(false);
      }
      return result.status;
    } catch (error) {
      console.error('Failed to fetch status:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!scriptLoaded) {
      return;
    }

    const fetchStatus = async (isInterval: boolean) => {
      try {
        const status = await fetchData(isInterval);
        if (status === 'complete') {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Failed to fetch status:', error);
      }
    };

    fetchStatus(false);

    // Set an interval to check the status every 60 seconds
    const intervalId = setInterval(() => {
      fetchStatus(true);
    }, intervalMs);

    // Cleanup the interval when the component is unmounted or if scriptLoaded becomes false
    return () => {
      clearInterval(intervalId);
    };
  }, [scriptLoaded]);

  React.useEffect(() => {
    if (!scriptLoaded) {
      return;
    }
    setLoading(true);

    // Clear existing timeout if there is one
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(async () => {
      const status = await fetchData(false);
      if (status === 'complete') {
        clearInterval(intervalId);
      }
    }, 2000); // 1000 milliseconds = 1 seconds

    // Set an interval to check the status every 60 seconds
    const intervalId = setInterval(async () => {
      const status = await fetchData(true);
      if (status === 'complete') {
        clearInterval(intervalId);
      }
    }, intervalMs);

    return () => {
      // Cleanup on unmount or if minutes changes within the 5 seconds
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      clearInterval(intervalId);
    };
  }, [minutes, states, sourceIds, daysBack]);

  const handleStatesChange = (value: string) => {
    setStates(value);
  };

  const handleNumbersChange = (value: string) => {
    setSourceIds(value);
  };

  const handleDaysBackChange = (value: string) => {
    setDaysBack(Number(value));
  };

  const onExportLeads = () => {
    exportLeads(clusterResult, groupType, exportType);
  };

  if (!clusterResult) {
    return (
      <div className="App">
        <header className="App-header">
          <h4 style={{ color: 'gray' }}>Application Loading</h4>
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      </div>
    );
  }
  let loadElement = <></>;
  if (loading || loadingClusters) {
    let text = 'Loading map data';
    if (loadingClusters) {
      text = 'Leave page open and check back later for cluster values';
    }
    loadElement = (
      <div className="map-header2">
        <div className="map-loading">{text}</div>
        <div className="map-loading">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
      </div>
    );
  }
  let noLeadsFound = <></>;
  if (!loading && !clusterResult?.leads?.length) {
    noLeadsFound = (
      <>
        <div className="map-loading">Leads not found</div>
      </>
    );
  }
  const clusterLines = (clusterResult?.clusters || []).map((c, index) => {
    return (
      <span key={`cluster-box-${index}`} className="clusters-box-row">
        <span className="cluster-box-blue">C{index + 1}</span>
        <span className="cluster-box-state">{c.state}</span>
        <span className="cluster-box-state">{c.zip}</span>
        <span className="cluster-box-len">{c.leads?.length}</span>
      </span>
    );
  });
  const totalDisplayedLines = (
    <>
      <span className="clusters-box-row">
        <span className="displayed-days-title">- 30 Days:</span>
        <b>{totals.lessThen30}</b>
      </span>
      <span className="clusters-box-row">
        <span className="displayed-days-title">+ 30 Days:</span>
        <b>{totals.lessThen60}</b>
      </span>
      <span className="clusters-box-row">
        <span className="displayed-days-title">+ 60 Days:</span>
        <b>{totals.lessThen90}</b>
      </span>
      <span className="clusters-box-row">
        <span className="displayed-days-title">+ 90 Days:</span>
        <b>{totals.greaterThen90}</b>
      </span>
      <span className="clusters-box-row">
        <span className="displayed-days-title displayed-days-total">Total:</span>
        <b className="displayed-days-total">{(clusterResult?.leads || []).length}</b>
      </span>
    </>
  );

  return (
    <>
      <div className="App">
        <div className="map-header">
          <div className="main-input">
            <DescriptiveInput
              label="Enter States (e.g., GA,NC,FL):"
              placeholder="GA,NC,FL"
              validator={validateStates}
              onValueChange={handleStatesChange}
            />
            <br />
            <DescriptiveInput
              label="Enter SourceIDs (e.g., 89,11,1):"
              placeholder="11,1,90"
              validator={validateNumbers}
              onValueChange={handleNumbersChange}
            />
          </div>
          <div className="main-input">
            <DescriptiveInput
              initValue={daysBack.toString()}
              label="Days Back"
              placeholder="Age in days of lead"
              validator={validateNumbers}
              onValueChange={handleDaysBackChange}
            />
            <br />
            <div className="minutes-dropdown">
              <span>Drive Time</span>
              <MinutesDropdown selectedOption={minutes as any} setSelectedOption={setMinutes as any} />
            </div>
          </div>
          <div className="group-type-dropdown">
            <label>Export Leads</label>
            <LeadGroupDropdown selectedOption={groupType as any} setSelectedOption={setGroupType as any} />
          </div>
          <div className="export-type-dropdown">
            <ExportTypeDropdown selectedOption={exportType as any} setSelectedOption={setExportType as any} />
          </div>
          <div className="export-button">
            <button className="react-select-style-button" type="button" onClick={onExportLeads}>
              Export
            </button>
          </div>
          {clusterLines?.length ? (
            <div className="main-input" style={{ marginRight: '-18px' }}>
              <span className="cluster-box-title">Cluster Counts</span>
              <div className="clusters-box">
                <span className="cluster-box-header" style={{ marginRight: '30px' }}>
                  &nbsp;
                </span>
                <span className="cluster-box-header" style={{ marginRight: '24px' }}>
                  ST
                </span>
                <span className="cluster-box-header" style={{ marginRight: '36px' }}>
                  ZIP
                </span>
                <span className="cluster-box-header">CT</span>
                {clusterLines}
              </div>
            </div>
          ) : (
            ''
          )}
          {clusterResult?.leads?.length ? (
            <div className="main-input">
              <span className="displayed-days-top">Total Displayed</span>
              <div className="clusters-box" style={{ width: '134px' }}>
                <span className="cluster-box-header" style={{ marginRight: '30px' }}>
                  &nbsp;
                </span>
                {totalDisplayedLines}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {loadElement}
        {noLeadsFound}
        <MapComponent
          locations={clusterResult?.leads || []}
          cluster1={clusterResult?.clusters?.length ? clusterResult?.clusters[0] : undefined}
          cluster2={clusterResult?.clusters?.length ? clusterResult?.clusters[1] : undefined}
          cluster3={clusterResult?.clusters?.length ? clusterResult?.clusters[2] : undefined}
          cluster4={clusterResult?.clusters?.length ? clusterResult?.clusters[3] : undefined}
          cluster5={clusterResult?.clusters?.length ? clusterResult?.clusters[4] : undefined}
        />
      </div>
    </>
  );
}

export default Main;
