import React from 'react';
import { DropdownProps } from './dropdown-props';
import Select from 'react-select';
import { OptionType } from './option-type';

export const exportTypeOptions: OptionType[] = [
  { label: '.xlsx', value: 1 },
  { label: '.csv', value: 0 }
];

export const ExportTypeDropdown: React.FC<DropdownProps> = ({ selectedOption, setSelectedOption }) => {
  return <Select value={selectedOption} onChange={setSelectedOption} options={exportTypeOptions} />;
};
