import React, { Fragment, useEffect, useState } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ResultCluster } from '../models/cluster-result.dto';

type Location = {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  lat: number;
  lng: number;
};

type Props = {
  locations: Location[];
  cluster1?: ResultCluster;
  cluster2?: ResultCluster;
  cluster3?: ResultCluster;
  cluster4?: ResultCluster;
  cluster5?: ResultCluster;
};

let mapCenter = { lat: -28.024, lng: 140.887 };
let mapCluster1: ResultCluster | undefined = undefined;
let mapCluster2: ResultCluster | undefined = undefined;
let mapCluster3: ResultCluster | undefined = undefined;
let mapCluster4: ResultCluster | undefined = undefined;
let mapCluster5: ResultCluster | undefined = undefined;

let mapLocations: Location[] = [];

async function addPurpleMarker(
  map: google.maps.Map,
  infoWindow: google.maps.InfoWindow,
  mapCluster: ResultCluster | undefined,
  clusterNum: number
) {
  if (!mapCluster) {
    return;
  }

  // Define the position for the purple marker
  const purpleMarkerPosition = mapCluster;

  const purplePinGlyph = new google.maps.marker.PinElement({
    glyph: `C${clusterNum}`, // Or any other label you want
    glyphColor: 'white',
    background: 'purple'
  });

  const purpleMarker = new google.maps.marker.AdvancedMarkerElement({
    position: purpleMarkerPosition,
    content: purplePinGlyph.element,
    zIndex: 101
  });

  purpleMarker.addListener('click', () => {
    infoWindow.setContent(`center zip ${mapCluster1?.zip} lead count ${(mapCluster1?.leads || []).length}`);
    infoWindow.open(map, purpleMarker);
  });

  // Set the marker directly on the map
  purpleMarker.map = map;
}

function createMarkerIcon(value: number) {
  const size1 = 28;
  const size2 = 14;
  const canvas = document.createElement('canvas');
  canvas.width = size1; // Adjust the size as needed
  canvas.height = size1;
  const context = canvas.getContext('2d') as any;

  // Draw circle
  context.beginPath();
  context.arc(size2, size2, size2, 0, 2 * Math.PI);
  context.fillStyle = '#2a33f2';
  context.fill();

  // Draw number
  context.fillStyle = 'white';
  context.font = '11px Arial'; // Adjust the font size and type as needed
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(value.toString(), size2, size2);

  return {
    url: canvas.toDataURL(),
    scaledSize: new google.maps.Size(size1, size1) // Adjust the size as needed
  };
}

async function initMap() {
  // Request needed libraries.
  const { Map, InfoWindow } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
  const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
    'marker'
  )) as google.maps.MarkerLibrary;

  const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
    zoom: 6,
    center: mapCenter,
    mapId: 'DEMO_MAP_ID'
  });

  const infoWindow = new google.maps.InfoWindow({
    content: '',
    disableAutoPan: true
  });

  await addPurpleMarker(map, infoWindow, mapCluster1, 1);
  await addPurpleMarker(map, infoWindow, mapCluster2, 2);
  await addPurpleMarker(map, infoWindow, mapCluster3, 3);
  await addPurpleMarker(map, infoWindow, mapCluster4, 4);
  await addPurpleMarker(map, infoWindow, mapCluster5, 5);

  // Add some markers to the map.
  const markers = mapLocations.map((position, i) => {
    // const marker = new google.maps.marker.AdvancedMarkerElement({
    //   position
    // });

    const markerIcon = createMarkerIcon(1); // Replace 10 with the actual number you want to display

    const marker = new google.maps.Marker({
      position, // Replace 'position' with the actual position object
      icon: markerIcon,
      map: map // Replace 'map' with the actual map object
    });

    // markers can only be keyboard focusable when they have click listeners
    // open info window when marker is clicked
    marker.addListener('click', () => {
      infoWindow.setContent(position.lat + ', ' + position.lng);
      infoWindow.open(map, marker);
    });
    return marker;
  });

  // Add a marker clusterer to manage the markers.
  new MarkerClusterer({ markers, map } as any);
}

let promise: any;

const MapComponent: React.FC<Props> = ({ locations = [], cluster1, cluster2, cluster3, cluster4, cluster5 }) => {
  useEffect(() => {
    mapLocations = locations;
    mapCenter = cluster1 || locations[0];
    mapCluster1 = cluster1;
    mapCluster2 = cluster2;
    mapCluster3 = cluster3;
    mapCluster4 = cluster4;
    mapCluster5 = cluster5;
    if (!promise) {
      promise = initMap();
      promise.then(() => {
        promise = undefined;
      });
    }
  }, [locations, cluster1]);

  return <></>;
};

export default MapComponent;
