export function dateBasedFileName(prefix: string, suffix: string) {
  let currentDate = new Date();
  let dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
  let [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second }
  ] = dateTimeFormat.formatToParts(currentDate);
  return `${prefix}_${year}${month}${day}_${hour}${minute}${second}${suffix}`;
}
