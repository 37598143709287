import React from 'react';
import Select from 'react-select';
import { OptionType } from './option-type';
import { DropdownProps } from './dropdown-props';

export const minuteDropdownOptions: OptionType[] = [
  { label: '30 minutes', value: 30 },
  { label: '60 minutes', value: 60 },
  { label: '90 minutes', value: 90 },
  { label: '120 minutes', value: 120 }
];

export const MinutesDropdown: React.FC<DropdownProps> = ({ selectedOption, setSelectedOption }) => {
  return <Select value={selectedOption} onChange={setSelectedOption} options={minuteDropdownOptions} />;
};
