import { ClusterLead, ClusterResultDto } from './models/cluster-result.dto';
import { OptionType } from './components/option-type';
import { unparse } from 'papaparse';
import { dateBasedFileName } from './util/date-base-file-name';

function convertISOtoDateStr(isoStr: string) {
  const date = new Date(isoStr);
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = date.getFullYear();

  return mm + '/' + dd + '/' + yyyy;
}

export const exportLeads = async (
  clusterResult: ClusterResultDto | undefined,
  groupType: OptionType,
  exportType: OptionType
) => {
  if (!clusterResult) {
    return [];
  }
  let leads: ClusterLead[] = [];
  if (groupType.value === 0) {
    leads = clusterResult?.leads || [];
  } else {
    leads = clusterResult?.clusters[groupType.value - 1]?.leads || [];
  }

  const exportRows = leads;
  const formattedRows = [];
  for (const row of exportRows) {
    formattedRows.push({
      recId: row.recId,
      state: row.state,
      zip: row.zip,
      city: row.city,
      address: row.address,
      createdAt: convertISOtoDateStr(row.createdAt),
      email: row.email,
      phone: row.phone,
      sourceId: row.sourceId
    });
  }

  if (exportType.value === 0) {
    const csv = unparse(formattedRows);
    const blob = new Blob([`\ufeff${csv}`], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = dateBasedFileName(`${groupType.label.replace(/ /g, '')}`, '.csv');
    link.click();
  } else {
    let XLSX = require('xlsx');
    let ws = XLSX.utils.json_to_sheet(formattedRows);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, dateBasedFileName(`${groupType.label.replace(/ /g, '')}`, '.xlsx'));
  }
};
